<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>

          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="customer_reference" name="customer_reference" ref="customer_reference"
                         :title="this.$i18n.t('form.process.customer_reference')" class="md-form-control" type="text"
                         :value="filters.filReferenciaCliente">
                  <label :class="{'active': filters.filReferenciaCliente}">{{ $t('form.process.customer_reference') }}</label>
                </div>
              </div>

<!--              <div>-->
<!--                <div class="md-form mb-0">-->
<!--                  <input id="hbl" name="hbl" ref="hbl" :title="this.$i18n.t('form.process.bl_house')"-->
<!--                         class="md-form-control" type="text" :value="filters.filHBL">-->
<!--                  <label :class="{'active': filters.filHBL}">{{ $t('form.process.bl_house') }}</label>-->
<!--                </div>-->
<!--              </div>-->

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}asas
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros por status -->
      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('notifications.status.unread')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <!--  Actions -->
                <div class="flexbox px-4 py-3 mb-3">
                  <div class="d-flex align-items-center">

                  </div>
                  <div class="dropdown">
                    <button class="btn btn-link dropdown-toggle text-muted" type="button" data-toggle="dropdown"
                            aria-expanded="false">Ações
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                         style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(92px, 38px, 0px);">
                      <a class="dropdown-item d-flex align-items-center" href="javascript:;" @click="markRead()">
<!--                        <i class="la la-envelope-o mr-3"></i>-->
                        Marcar como lida</a>
                      <a class="dropdown-item d-flex align-items-center" href="javascript:;" @click="markAllRead()">
<!--                        <i class="la la-envelope-o mr-3"></i>-->
                        {{ $t('notifications.mark_all_as_read') }}</a>
                    </div>
                  </div>
                </div>
                <!--  End Actions -->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="messageAlertEndpoint"
                            :fields="vTableFields"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id"
                            :row-class="onRowClass"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <template slot="message" slot-scope="props">
                      <div class="message-content mt-2" v-html="showMessage(props.rowData)" ></div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'

// MultipleSelect
import FilterContainer from '@/components/FilterContainer'

import MyActionComponent from './MyActionComponent'

import dayjs from 'dayjs'
import MessageAlertsService from '@/services/MessageAlertsService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'NotificationList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('notifications.title') + ' - %s'
    }
  },
  data () {
    return {
      selected: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      messageAlertEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/message-alert',
      multiSort: true,
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {
        id_status: 2
      },
      fieldsTable: [
        {
          name: MyActionComponent,
          width: '170px',
          dataClass: 'text-center',
          titleClass: 'text-center'

        },
        {
          name: 'date_create',
          title: this.$i18n.t('form.messageAlert.date'),
          sortField: 'dateCreate',
          formatter: (value) => this.formatDateTime(value, undefined, ' - ')
        },
        {
          name: 'title',
          title: this.$i18n.t('form.messageAlert.action'),
          // sortField: 'title',
          formatter: (value) => this.formatTextType(value)
        },
        {
          name: 'customer_name',
          title: this.$i18n.t('form.messageAlert.customer'),
          dataClass: 'text-left',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'numero_processo',
          title: this.$i18n.t('form.messageAlert.process'),
          dataClass: 'text-left',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'message',
          title: this.$i18n.t('form.messageAlert.message'),
          dataClass: 'text-left',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sortOrder: [
        { field: 'dateCreate', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    markkRead (msgAlert) {
      let me = this
      me.isLoading = true
      MessageAlertsService.setMessageRead(msgAlert.id, this.$store.getters.userInfo.id).then(res => {
        me.$router.push({ path: `/processdetail?${msgAlert.id_process}` })
        me.isLoading = false
      })
    },
    markRead () {
      let me = this
      me.isLoading = true

      let selectedMessages = this.getSelected()

      MessageAlertsService.setMessagesRead(selectedMessages, this.$store.getters.userInfo.id).then(res => {
        me.isLoading = false
        me.$refs.vuetable.refresh()
      })
    },
    markAllRead () {
      let me = this
      this.$alertSwal.modalConfirm('', 'Esta ação irá marcar todos os alertas como lido, está certo disso?', 'warning', 'Sim', 'Não', (e) => MessageAlertsService.setAllMessagesRead(this.$store.getters.userInfo.id)).then((res) => {
        if (res.value) {
          me.$alertSwal.toast('Todos os alertas marcados como lido!')
          me.$refs.vuetable.refresh()
        }
      })
    },
    getSelected () {
      if (this.$refs.vuetable) {
        return this.$refs.vuetable.selectedTo
      }
    },
    hasSelected () {
      if (this.$refs.vuetable && this.$refs.vuetable.selectedTo.length > 0) {
        return true
      }

      return false
    },
    onRowClass (rowData, index) {
      if (rowData.date_read == null) {
        return 'unread'
      }

      return ''
    },
    alertDate (dateTime) {
      let date = new Date(dateTime)
      let locale = process.env.VUE_APP_locale
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    buildFilters () {
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      this.filter.customer_reference = this.filters.filReferenciaCliente
      this.filter.hbl = this.filters.filHBL
      this.filter.id_status = null
      this.filter.arrival_begin_date = this.filters.filPeriodoEmbarqueDtInicio
      this.filter.arrival_end_date = this.filters.filPeriodoEmbarqueDtFim
      this.filter.departure_begin_date = this.filters.filPeriodoDesembarqueDtInicio
      this.filter.departure_end_date = this.filters.filPeriodoDesembarqueDtFim
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      let dayParse = 'DD/MM/YYYY'

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      this.filters.filProcesso = this.$refs.internal_code.value
      this.filters.filReferenciaCliente = this.$refs.customer_reference.value
      this.filters.filHBL = this.$refs.hbl.value
      // this.filters.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = dayjs(this.$refs.arrival_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoEmbarqueDtFim = dayjs(this.$refs.arrival_end_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_end_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtInicio = dayjs(this.$refs.departure_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtFim = dayjs(this.$refs.departure_end_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_end_date.value, dayParse).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      // this.filter.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      // this.filter.internal_code = null
      // this.filter.customer_reference = null
      // this.filter.hbl = null
      // // this.filter.id_status = null
      // this.filter.arrival_begin_date = null
      // this.filter.arrival_end_date = null
      // this.filter.departure_begin_date = null
      // this.filter.departure_end_date = null
      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    formatTextType (value) {
      if (!value) {
        return ' - '
      }

      return this.$i18n.t('alert_messages.title.' + value)
    },
    formatType (value) {
      if (!value) {
        return ' - '
      }

      value = parseInt(value)

      let typeList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.tier.typeList[1]'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.tier.typeList[2]'),
          'color': '#ababab'
        }
      ]

      let status = typeList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return status.label
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.task.status.active'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.task.status.inativo'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.task.status.expired'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.markkRead(row.data)
    },
    showMessage (message) {
      let translatedMessage = this.$i18n.t('alert_messages.message.' + message.message)

      if (message.args) {
        try {
          let args = JSON.parse(message.args)
          let date = new Date(args.event_date.date)

          // TODO: Change do args iterated in a for function
          translatedMessage = translatedMessage.replace('%indicator%', args.indicator)
          translatedMessage = translatedMessage.replace('%date%', this.alertDate(date))
          if (args.name) {
            translatedMessage = translatedMessage.replace('%name%', args.name)
          }
        } catch (e) {
          // return false;
        }
      }

      return translatedMessage
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },

    process () {
      return this.$route.params.process || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }

  .table-hover tbody tr.unread, .table-hover tbody tr.unread td, .table-hover tbody tr.unread td span, .table-hover tbody tr.unread td div
  {
    font-family: 'Pluto Sans Medium' !important;
  }
</style>

<style scoped>

</style>
