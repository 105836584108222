<template>
  <th v-if="isHeader" class="vuetable-th-component-checkbox">
    <label class="checkbox checkbox-primary"><input type="checkbox"
                                                    @change="toggleAllCheckbox($event)"
                                                    :checked="isAllItemsInCurrentPageSelected()"
    ><span></span></label>
  </th>
  <td v-else
      class="vuetable-td-component-checkbox"
  >
    <label class="checkbox checkbox-primary"><input type="checkbox"
           @change="toggleCheckbox(rowData, $event)"
           :checked="isSelected(rowData)"
    ><span></span></label>
  </td>
</template>
<script>
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'

export default {
  name: 'my-action-component',
  mixins: [VuetableFieldCheckboxMixin]
}
</script>
